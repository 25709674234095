// header styles

@header-height: 40px;
@header-mobile-height: 66px;
@header-spacing: @line-height-computed;
@progressbar-height: 6px;

#bm-header {
	height: @header-height + @header-spacing;
}

#bm-header-mobile {
	height: @header-mobile-height;
}

#bm-nav-mobile {
	position: fixed;
	top: 32px;
	bottom: 0;
	height: 100%;
	width: 100%;
	z-index: @zindex-mobile-menu;
	font-size: @font-size-h3;	
	display: none;
}

#bm-header, #bm-header-mobile, #bm-nav-mobile, .dropdown-menu, .affix  {
	background-color: @body-bg;
	color: @text-color;
}

#bm-header, #bm-header-mobile {
	position: fixed;
	top: 0;
	width: 100%;
	z-index: @zindex-navbar-fixed;
	font-size: @font-size-h3;

	-moz-box-shadow: 	0px 8px 16px fade(@gray-header, 50%);
	-webkit-box-shadow: 0px 8px 16px fade(@gray-header, 50%);
	-o-box-shadow: 		0px 8px 16px fade(@gray-header, 50%);
	box-shadow: 		0px 8px 16px fade(@gray-header, 50%);

	.site-title, .cat-title {
		
		font-size: @font-size-h3;

		text-rendering: optimizeLegibility;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		
		@media (min-width: @screen-md-min) { font-size: @font-size-h1; }

		padding: 0;
  		&:hover,
  		&:focus {
  			text-decoration: none;
  		}
	}
	
	.site-title {
		color: @text-color;
	}

	.cat-title {
		@media (min-width: @screen-md-min) { 
			margin-left: @grid-gutter-width*0.5; 
		}
	}

	.sub-header-modile {
		margin-top: -@line-height-computed*0.5;
	}

	.site-desc { 
		font-family: @font-system;
		font-size: 9px; 
		margin-top: @line-height-computed*0.75;
		@media (min-width: @screen-md-min) { 

			margin-left: @grid-gutter-width*0.5; 
			font-size: @font-size-base;
		}
	}

	.btn:focus, .btn:active:focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn.active.focus {
		outline: none;
	}

	.btn-link:hover, .btn-link:focus {
		text-decoration: none;
	}

	.dropdown-menu {
		border: none;
		color: @gray-text;
		font-size: @font-size-h3;

		.cat-list > li {
			border-top: 1px solid @gray-border;
			//padding-top: @line-height-computed*0.5;
			//margin-top: @line-height-computed*0.5
		}

		li > a {
			color: inherit;
			padding-left: @grid-gutter-width*0.5;
			padding-right: @grid-gutter-width*0.5;
		}
		li.cat-news    { .set-hoverColor(@cat-news, @body-bg); }
		li.cat-societe { .set-hoverColor(@cat-societe, @body-bg); }
		li.cat-videos  { .set-hoverColor(@cat-videos , @body-bg); }
		li.cat-culture { .set-hoverColor(@cat-culture, @body-bg); }
		li.cat-photos  { .set-hoverColor(@cat-photos , @body-bg); }
		li.cat-opinion { .set-hoverColor(@cat-opinion, @body-bg); }
	}
}

#bm-nav-mobile {
	.cat-list {
		li {
		width:100%;
		padding: @grid-small-gutter-width*0.5;
		//padding-right: @grid-small-gutter-width*0.5;

			&.specific-mobile-cat {
				border-top: none;
				width:50%;
			}
		}	
	}

	.btn-lang {
		background-color: @gray-header;
		color: @gray-text;
		border: 1px solid @gray-text;
	}

	.space-v-bottom {
		margin-bottom: @line-height-computed;
	}
}

.search-btn {
	background-color: @gray-header;
	color: @gray-text;
}

#switchLanguage, #btn-search {
	font-size: @font-size-h3;
}


.header-margin {
	margin-top: @header-height + @header-spacing;
}

.header-margin-large {
	margin-top: @header-height + @header-spacing + @line-height-computed;
}


// * Progress bar **********************************************

#progress-wrapper {
	position: fixed;
	top: @header-mobile-height - (@progressbar-height);
	@media (min-width: @screen-md-min) { 
		top: @header-height + @header-spacing - @progressbar-height;
	}
	left: 0;
	z-index: @zindex-progressbar;
}

#progress-element {
	background-color: @text-color;
	height: @progressbar-height - 2;
	@media (min-width: @screen-md-min) { 
		height: @progressbar-height;
	}
}

// * Sticky post title on scroll **********************************************

.affix {
	top: @header-mobile-height;
	@media (min-width: @screen-md-min) { 
		top: @header-height + @header-spacing;
	}
	z-index: @zindex-navbar;

	-moz-box-shadow: 	0px 8px 16px fade(@gray-header, 50%);
	-webkit-box-shadow: 0px 8px 16px fade(@gray-header, 50%);
	-o-box-shadow: 		0px 8px 16px fade(@gray-header, 50%);
	box-shadow: 		0px 8px 16px fade(@gray-header, 50%);

	.post-title.display { 
		font-size: @font-size-base;
		font-family: @font-system;
		margin-top: 8px; 
		@media (min-width: @screen-md-min) {
			font-family: @font-family-sans-serif;
			font-size: @font-size-h1; 
			margin-top: @line-height-computed*0.5;
		}
		
		margin-bottom: @line-height-computed*0.25;
	}

	.post-meta { 
		display: none;
		@media (min-width: @screen-md-min) {
			display: block;
		}
		font-size: @font-size-base*0.75;
		margin-top: 0;
	}
	
}