// THE BLACK MAIL - Template
// bm-layout.less -> custom blackmail class 
//


// Colors
.set-color(@color: @gray-lighter){
  color: @color;
  * {
    color: @color;
  }
  li a:hover, li a:focus,
  a:hover,  a:focus {
      text-decoration: none;
  }
}

.set-hoverColor(@color: @gray-text, @hover-color: @gray-background) {
  color: @color; 
  * {
    color: @color;
  }
  li a:hover, li a:focus,
  a:hover,  a:focus {
      text-decoration: none;
      color: @hover-color;
      background-color: @color;
  }
}


// Category classes
.cat-news     { .set-color(@cat-news);    }
.cat-societe  { .set-color(@cat-societe);  }
.cat-videos   { .set-color(@cat-videos);  }
.cat-culture  { .set-color(@cat-culture);  }
.cat-photos   { .set-color(@cat-photos);  }
.cat-opinion  { .set-color(@cat-opinion);  }


// Horizontal line
hr {
  margin-top: @line-height-computed*0.5;
  margin-bottom: @line-height-computed*0.5;
  border: 0;
  border-top: 1px solid @gray-border;
}


@media (min-width: @screen-md-min) {
  .cat-list-footer li {
    border-bottom: 1px solid @gray-border;
    padding-bottom: @line-height-computed*0.5;
    margin-bottom: @line-height-computed*0.5;
  }
}


.cat-list li {
  border-bottom: 1px solid @gray-border;
  /*padding-bottom: @line-height-computed*0.5;
  margin-bottom: @line-height-computed*0.5*/
}

.divided-list {
  li:not(:last-of-type) {
    border-right: 1px solid @gray-border;
  }
}

.right-separator {
  border-right: 1px solid @gray-border; 
}


// Posts fluid-container 
.post-container {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.container-fluid {
  &.not-full {
    padding-left: @grid-small-gutter-width/2;
    padding-right: @grid-small-gutter-width/2;

    @media (min-width: @screen-md-min) {
      width: 90%;
    }
  }
}

// ** Post classes *******************************************************

.post-header {
  margin-bottom: 0;
}

.post-title {
  font-family: @font-family-sans-serif;
  font-size: @font-size-h4; 

  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @media (min-width: @screen-md-min) { font-size: @font-size-h3; }

  &.display { 
    font-size: @font-size-h3; 
    line-height: 1.1em;
    margin-top: @line-height-computed;

    @media (min-width: @screen-sm-min) {
      font-size: @font-size-h1; 
      line-height: 1em;
      
    }

    @media (min-width: @screen-md-min) {
      font-size: @font-size-h1*2; 
      line-height: 1em;
      
    }
  }

  &.h1 {
    @media (min-width: @screen-md-min) {
      font-size: @font-size-h1;
    }
  }
   
  margin-top: @line-height-computed*0.5;

}

.post-desc {
  font-family: @font-family-serif;
  a:hover { text-decoration: none; }

  &.display { 
    font-size: @font-size-h3; 
    font-family: @font-family-sans-serif;
    line-height: 1.1em;

    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

  }

}

.post-article {
    font-size: @font-size-base;
    font-family: @font-family-serif;
}

.post-excerpt {
  font-size: @font-size-h3;
  font-family: @font-family-sans-serif;
  line-height: 1.1em;

  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  margin-bottom: @line-height-computed;
}

.post-image {
  img { margin-bottom: @line-height-computed*0.5; }

  margin-bottom: @line-height-computed*2;
}

.post-caption {
  font-family: @font-system;
  font-size: 12px;

}

.post-meta {
  margin-top: @line-height-computed*0.5;
  font-family: @font-family-monospace;
  font-size: 13px;
  //small { font-size: 75%; }

  // Adjust font-size on small device
  @media (max-width: @screen-sm-max) {
    font-size: 11px;
    /*font-size: @font-size-base*0.75;
    line-height: @line-height-computed*0.75;
    small { font-size: @font-size-base*0.75;}*/
    margin: @line-height-computed*0.25 0;
  }

  > a.category {
    background-color: @text-color;
    border: 1px solid @text-color;
    color: @body-bg;
    padding: 3px 6px;
  }

  >a.category:hover {
    color: @text-color;
    background-color: @body-bg;
    text-decoration: none;
  }
}


// ** Grid gutter settings on small device *************************************************

div[class^="col"], *[class^="col"], p[class^="col"]{padding-left:@grid-small-gutter-width/2; padding-right:@grid-small-gutter-width/2;}
.row { margin-left: -@grid-small-gutter-width/2; margin-right: -@grid-small-gutter-width/2;}

// Fix forms and container gutter on small device
@media (max-width: @screen-sm-max) {  
  .container-fluid {
    padding-left: @grid-small-gutter-width*0.5;
    padding-right: @grid-small-gutter-width*0.5;
  }
  .form-horizontal .form-group {
      margin-left: -@grid-small-gutter-width*0.5;
      margin-right: -@grid-small-gutter-width*0.5;
  }
}
 
/* Large devices (large desktops, 1200px and up) */
@media (min-width: @screen-md-min) {   
    /*default so you don't need this*/
    div[class^="col"], *[class^="col"]{padding-left: @grid-gutter-width/2; padding-right:@grid-gutter-width/2;}
    .row { margin-left: -@grid-gutter-width/2; margin-right: -@grid-gutter-width/2;}
}

// Forms

.form-control {
    color: @text-color;
    background-color: @body-bg;
    border: 1px solid @gray-border;
}


// Separator
.separator {
  border-top: 1px solid @gray-border;
  margin-left: @grid-gutter-width*0.5;
  margin-right: @grid-gutter-width*0.5;
  height: 1px;
}

.heading-border-top {
  margin-top: 0;
  padding-top: @line-height-computed;
  border-top: 1px solid @gray-border;
}

.heading-border-bottom {
  border-bottom: 1px solid @gray-border;
}



// Styling on warning for users on IE7 or below
.browsehappy {
  text-align: center;
  margin: 0;
  background: #fff8e2;
  color: #000;
  padding: 0.5em 0;
}


.font-bc {
  font-family: @bc-regular;
  &.bold {
    font-family: @bc-bold;
  }
}

.font-mono {
  font-family: 'Space Mono', monospace;
}

.font-serif {
  font-family: 'Merriweather', monospace;
}