// bm-type.less

h1, .h1 { font-size: @font-size-h1; }
h2, .h2 { font-size: @font-size-h2; }
h3, .h3 { font-size: @font-size-h3; }
h4, .h4 { font-size: @font-size-h4; }
h5, .h5 { font-size: @font-size-h5; }
h6, .h6 { font-size: @font-size-h6; }



h1, .h1 {
	@media (max-width: @screen-sm-max) {
		margin-top: @line-height-computed*0.5;
	}
}


// Headings

body {
	font-size: 12px;
	//line-height: 16px;

	@media (min-width: @screen-sm-min) {
		font-size: @font-size-base;
	}
}











@media (min-width: @screen-lg-min) {

}