// footer styles

#bm-footer {
	margin-top: @line-height-computed;
	margin-bottom: @line-height-computed*3;

	.form-inline {
		margin-bottom: @line-height-computed*3;
	}

	.footer-logo {
		margin-top: @line-height-computed;
		width: 40%;
		height: auto;
	}
}