// THE BLACK MAIL - Module
// post-cover.less 
//
// Post cover module


.post-cover, .post-header {
  width: 100%;

  margin-bottom: @line-height-computed;

  .cover-image {
    height: 70vh;
    background-image: url('../images/photos/bm_08.jpg');
    background-size: cover;
  }
  /*
  .post-desc {
  	font-size: @font-size-base;
  	font-family: @font-family-serif;

  	@media (min-width: @screen-md-min) {
  		font-size: @font-size-h3;
  		font-family: @font-family-sans-serif;
      line-height: 1.2em;
  	}
  }
  */
}

.post-cover {
  @media (min-width: @screen-md-min) {
    margin-bottom: @line-height-computed*3;
  }
}