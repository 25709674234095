// post-preview styles
 
.post-preview {


	@media (min-width: @screen-md-min) {
		margin-bottom: @line-height-computed*2;
	}



	.video-wrapper{
		position: relative;
		padding-bottom: 56.25%; /* 16:9 */
		padding-top: 25px;
		height: 0;

		 iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
}